<script>
  import accounting from "accounting";
  import {
    specifiedTypes,
    ownerTypes,
    businessTypes,
    businessProjection,
    stateOptions
  } from "components/business_projection/Store";
  import TextField from "./fields/TextField.svelte";
  import NumericField from "./fields/NumericField.svelte";
  import DateField from "./fields/DateField.svelte";
  import SelectField from "./fields/SelectField.svelte";
  import CheckboxField from "./fields/CheckboxField.svelte";
  import moment from "moment";

  export let sourceTitle = "QuickBooks";
  export let personalProjectionPath;

  function formatMoney(val) {
    return accounting.formatMoney(val, {
      symbol: "$",
      precision: 0,
      format: {
        pos: "%s%v",
        neg: "(%s%v)",
        zero: "%s%v"
      }
    });
  }
</script>

<table class="table business-projection-form">
  <tbody>
    <tr class="projection-row__settings">
      <td class="projection-row__title">Owner:</td>
      <td>
        <SelectField key="owner" options={ownerTypes} />
      </td>
      <td class="projection-row__title">Company Name:</td>
      <td>
        <TextField key="company-name" />
      </td>
    </tr>
    <tr class="projection-row__settings">
      <td class="projection-row__title">Business Type:</td>
      <td>
        <SelectField key="business-type" options={businessTypes} />
      </td>
      <td class="projection-row__title">Business Start:</td>
      <td>
        <DateField key="business-start" />
      </td>
    </tr>
    {#if !$businessProjection.isCCorp}
      <tr class="projection-row__settings">
        <td class="projection-row__title">Payroll:</td>
        <td>
          <NumericField key="payroll" placeholder="0" />
        </td>
        <td class="projection-row__title">Fixed Assets:</td>
        <td>
          <NumericField key="fixed-assets" placeholder="0" />
        </td>
      </tr>
      <tr class="projection-row__settings">
        <td class="projection-row__title">Specified Service Business:</td>
        <td>
          <SelectField key="is-specified" options={specifiedTypes} />
        </td>
        <td class="projection-row__title">Rental Real Estate Business:</td>
        <td>
          <CheckboxField key="rental-real-estate" />
        </td>
      </tr>
    {/if}
    <tr class="projection-row__settings">
      <td class="projection-row__title">State:</td>
      <td>
        <SelectField selectDisabled={true} key="business-state" options={stateOptions} />
        <small>
          * For now, the state can only be changed for the entire <a href={personalProjectionPath}>
            personal projection
          </a>
        </small>
      </td>
    </tr>
    <tr class="projection-row projection-row__calc">
      <th class="project-row__no-line-before">
        <h1 class="projection-row__header">Income</h1>
      </th>
      <th class="form-inline project-row__no-line-before">
        In {sourceTitle} ({moment($businessProjection.startDate).format("l")} - {moment(
          $businessProjection.endDate
        ).format("l")} data is considered)
      </th>
      <th class="project-row__no-line-before">
        Projected Amounts for {new Date(new Date().getFullYear(), 11, 31).toLocaleDateString("en-US")}
      </th>
      <th class="project-row__no-line-before">
        Override Projected Amounts for {new Date(new Date().getFullYear(), 11, 31).toLocaleDateString(
          "en-US"
        )} (Federal)
      </th>
      <th class="project-row__no-line-before">
        Override Projected Amounts for {new Date(new Date().getFullYear(), 11, 31).toLocaleDateString(
          "en-US"
        )} (State)
      </th>
    </tr>
    {#each $businessProjection.incomeEntries as entry, index}
      <tr class="projection-row">
        <td class="projection-row__title">{entry.name}:&nbsp;</td>
        <td>{entry.actual != null ? formatMoney(entry.actual) : ""}</td>
        <td>{entry.projection != null ? formatMoney(entry.projection) : ""}</td>
        <td>
          <NumericField
            key="income-entries-{index}-federal-override"
            getter={() => entry.federalOverride}
            setter={(e) => (entry.federalOverride = e.target.value)}
            placeholder={formatMoney(entry.projection)}
          />
        </td>
        <td>
          <NumericField
            key="income-entries-{index}-state-override"
            getter={() => entry.stateOverride}
            setter={(e) => (entry.stateOverride = e.target.value)}
            placeholder={formatMoney(entry.projection)}
          />
        </td>
      </tr>
    {/each}
    <tr class="projection-row projection-row__total projection-row__total-income">
      <td class="projection-row__title">Total Income:&nbsp;</td>
      <td>
        {$businessProjection.totalIncome.actual != null
          ? formatMoney($businessProjection.totalIncome.actual)
          : ""}
      </td>
      <td>
        {$businessProjection.totalIncome.projection != null
          ? formatMoney($businessProjection.totalIncome.projection)
          : ""}
      </td>
      <td>
        {$businessProjection.totalIncome.federalFinalProjection != null
          ? formatMoney($businessProjection.totalIncome.federalFinalProjection)
          : ""}
      </td>
      <td>
        {$businessProjection.totalIncome.stateFinalProjection != null
          ? formatMoney($businessProjection.totalIncome.stateFinalProjection)
          : ""}
      </td>
    </tr>

    <tr class="projection-row">
      <td class="project-row__no-line-before" colspan="4">
        <h1 class="projection-row__header">Deductions</h1>
      </td>
    </tr>
    {#each $businessProjection.expenseEntries as entry, index}
      <tr class="projection-row">
        <td class="projection-row__title">{entry.name}:&nbsp;</td>
        <td>{entry.actual != null ? formatMoney(entry.actual * -1) : ""}</td>
        <td>{entry.projection != null ? formatMoney(entry.projection * -1) : ""}</td>
        <td>
          <NumericField
            key="expense-entries-{index}-federal-override"
            getter={() => entry.federalOverride}
            setter={(e) => (entry.federalOverride = e.target.value)}
            placeholder={formatMoney(entry.projection * -1)}
          />
        </td>
        <td>
          <NumericField
            key="expense-entries-{index}-state-override"
            getter={() => entry.stateOverride}
            setter={(e) => (entry.stateOverride = e.target.value)}
            placeholder={formatMoney(entry.projection * -1)}
          />
        </td>
      </tr>
    {/each}
    <tr class="projection-row projection-row__total projection-row__total-deductions">
      <td class="projection-row__title">Total Deductions:&nbsp;</td>
      <td>
        {$businessProjection.totalExpenses.actual != null
          ? formatMoney($businessProjection.totalExpenses.actual * -1)
          : ""}
      </td>
      <td>
        {$businessProjection.totalExpenses.projection != null
          ? formatMoney($businessProjection.totalExpenses.projection * -1)
          : ""}
      </td>
      <td>
        {$businessProjection.totalExpenses.federalFinalProjection != null
          ? formatMoney($businessProjection.totalExpenses.federalFinalProjection * -1)
          : ""}
      </td>
      <td>
        {$businessProjection.totalExpenses.stateFinalProjection != null
          ? formatMoney($businessProjection.totalExpenses.stateFinalProjection * -1)
          : ""}
      </td>
    </tr>
    <tr class="projection-row projection-row__total projection-row__allowable-deductions">
      <td class="projection-row__title">Allowable Deductions:&nbsp;</td>
      <td>
        {$businessProjection.allowableDeductions.actual != null
          ? formatMoney($businessProjection.allowableDeductions.actual * -1)
          : ""}
      </td>
      <td>
        {$businessProjection.allowableDeductions.projection != null
          ? formatMoney($businessProjection.allowableDeductions.projection * -1)
          : ""}
      </td>
      <td>
        {$businessProjection.allowableDeductions.federalFinalProjection != null
          ? formatMoney($businessProjection.allowableDeductions.federalFinalProjection * -1)
          : ""}
      </td>
      <td>
        {$businessProjection.allowableDeductions.stateFinalProjection != null
          ? formatMoney($businessProjection.allowableDeductions.stateFinalProjection * -1)
          : ""}
      </td>
    </tr>
    <tr class="projection-row">
      <td class="project-row__no-line-before" colspan="4">
        <h1 class="projection-row__header">Net Income Summary</h1>
      </td>
    </tr>
    <tr class="projection-row projection-row__total projection-row__net-income">
      <td class="projection-row__title">Net Income:&nbsp;</td>
      <td>
        {$businessProjection.netIncome.actual != null
          ? formatMoney($businessProjection.netIncome.actual)
          : ""}
      </td>
      <td>
        {$businessProjection.netIncome.projection != null
          ? formatMoney($businessProjection.netIncome.projection)
          : ""}
      </td>
      <td>
        {$businessProjection.netIncome.federalFinalProjection != null
          ? formatMoney($businessProjection.netIncome.federalFinalProjection)
          : ""}
      </td>
      <td>
        {$businessProjection.netIncome.stateFinalProjection != null
          ? formatMoney($businessProjection.netIncome.stateFinalProjection)
          : ""}
      </td>
    </tr>
    <tr class="projection-row projection-row__total projection-row__taxable-income">
      <td class="projection-row__title">Taxable Income:&nbsp;</td>
      <td>
        {$businessProjection.taxableIncome.actual != null
          ? formatMoney($businessProjection.taxableIncome.actual)
          : ""}
      </td>
      <td>
        {$businessProjection.taxableIncome.projection != null
          ? formatMoney($businessProjection.taxableIncome.projection)
          : ""}
      </td>
      <td>
        {$businessProjection.taxableIncome.federalFinalProjection != null
          ? formatMoney($businessProjection.taxableIncome.federalFinalProjection)
          : ""}
      </td>
      <td>
        {$businessProjection.taxableIncome.stateFinalProjection != null
          ? formatMoney($businessProjection.taxableIncome.stateFinalProjection)
          : ""}
      </td>
    </tr>
    <tr class="projection-row">
      <td class="projection-row__title">
        {#if $businessProjection.isCCorp}
          Dividends Distribution, %:
        {:else}
          Ownership, %:
        {/if}
      </td>
      <td>
        <NumericField key="business-share-percent" placeholder="100" />
      </td>
      <td>{$businessProjection.businessSharePercent}</td>
      <td>{$businessProjection.businessSharePercent}</td>
      <td>{$businessProjection.businessSharePercent}</td>
    </tr>
  </tbody>
</table>

<style lang="scss">
  a:link {
    text-decoration: underline;
  }
</style>
